import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FallingEmojis } from './FallingEmoji';

const headlines = [
  "ttyl... Is this a bull or bear market for my feelings?",
  "ttyl... What's the current exchange rate for \"later\" to \"now\"?",
  "ttyl... Can I short sell my expectations?",
  "ttyl... Is there a futures contract for actual replies?",
  "ttyl... How do I hedge against ghosting?",
  "ttyl... Are my DMs experiencing a recession?",
  "ttyl... What's the ROI on waiting for \"later\"?",
  "ttyl... Is this the dip I shouldn't buy?",
  "ttyl... Can I leverage my hope for faster returns?",
  "ttyl... Is \"later\" a volatile or stable investment?",
  "ttyl... but will you really?",
  "ttyl... Is that a promise or a goodbye?",
  "ttyl... Should I keep my phone close?",
  "ttyl... Why does this feel like the end?",
  "ttyl... How long is \"later\"?",
  "ttyl... its over",
  "ttyl... Are we just postponing goodbye?",
  "ttyl... Is this hope or heartbreak?",
  "ttyl... Do you mean it this time?",
  "ttyl... Can I believe in \"later\"?",
  "ttyl... Why does three hours feel like forever?"
];

const RotatingHeadline = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % headlines.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.h1
        key={index}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
        className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white text-center mb-4 sm:mb-6 px-2"
        style={{ fontFamily: 'SuperMario256, sans-serif' }}
      >
        {headlines[index]}
      </motion.h1>
    </AnimatePresence>
  );
};

const RotatingImage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = ['image1.png', 'image2.png', 'image3.png', 'image4.png'];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-full max-w-xs sm:max-w-sm md:max-w-md mx-auto h-48 sm:h-56 md:h-64 flex items-center justify-center">
      <AnimatePresence mode="wait">
        <motion.img
          key={currentImage}
          src={images[currentImage]}
          alt={`TTYL ${currentImage + 1}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1.2 }}
          transition={{ duration: 0.5 }}
          className="max-h-full max-w-full object-contain"
        />
      </AnimatePresence>
    </div>
  );
};

const TTYLMarioText = () => (
  <div className="text-center mt-6 sm:mt-8">
    <h2 
      className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-2"
      style={{ fontFamily: 'SuperMario256, sans-serif' }}
    >
      work in process... TTYL
    </h2>
  </div>
);

const SadButton = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-bold text-sm sm:text-base lg:text-lg hover:from-blue-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl m-2"
    style={{ fontFamily: 'SuperMario256, sans-serif' }}
  >
    {children}
  </a>
);

const CopyableContract = () => {
  const [copied, setCopied] = useState(false);
  const contractAddress = "4dmFvnT5F2goBZ9MxdQoNV86hE1eAvsftvauuttkpump";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="mt-6 sm:mt-8 text-center">
      <button
        onClick={copyToClipboard}
        className="bg-gradient-to-r from-gray-700 to-gray-900 text-white px-3 sm:px-4 py-2 rounded-full text-sm sm:text-base hover:from-gray-800 hover:to-black transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl"
        style={{ fontFamily: 'SuperMario256, sans-serif' }}
      >
        {copied ? "Copied! 😢" : "Copy Contract Address 📋"}
      </button>
      <p className="mt-2 text-xs sm:text-sm text-gray-400 font-mono break-all px-4">{contractAddress}</p>
    </div>
  );
};

const Home = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-900 to-black text-white overflow-hidden relative">
      <FallingEmojis count={20} />
      <div className="container mx-auto px-4 py-8 sm:py-12 relative z-10 flex flex-col justify-center min-h-screen">
        <RotatingHeadline />
        <RotatingImage />
        <TTYLMarioText />
        <div className="mt-6 sm:mt-8 text-center">
          <SadButton href="https://x.com/talktoyouulater">
            Follow on Twitter 😢
          </SadButton>
          <SadButton href="https://pump.fun/4dmFvnT5F2goBZ9MxdQoNV86hE1eAvsftvauuttkpump">
            Go to Pump.fun 💔
          </SadButton>
          <SadButton href="https://maker.ttyl.meme">
            Enter Meme Maker (Super Early Beta) 🎭
          </SadButton>
        </div>
        <CopyableContract />
      </div>
    </div>
  );
};

export default Home;