import React, { useEffect } from 'react';
import './Ttyl.scss'; // Make sure to create this CSS file with the scoped styles

const Ttyl = () => {
  useEffect(() => {
    const checkbox = document.querySelector('.ttyl-section input[name=mode]');
    checkbox.addEventListener('change', function() {
      if(this.checked) {
        trans()
        document.querySelector('.ttyl-section').setAttribute('data-theme', 'dark')
      } else {
        trans()
        document.querySelector('.ttyl-section').setAttribute('data-theme', 'light')
      }
    })

    function trans() {
      document.querySelector('.ttyl-section').classList.add('transition');
      window.setTimeout(() => {
        document.querySelector('.ttyl-section').classList.remove('transition');
      }, 1000)
    }
  }, []);

  return (
    <div className="ttyl-section">
      <div className="switch-mode-blk">
        <h5>Day1 / Night</h5>
        <input className="container_toggle" type="checkbox" id="switch" name="mode" />
        <label htmlFor="switch"></label>
      </div>
      <div className="bg">
        <div className="mountains">
          <div className="mountain"></div>
          <div className="mountain"></div>
          <div className="mountain"></div>
          <div className="mountain"></div>
          <div className="mountain"></div>
          <div className="mountain"></div>
          <div className="mountain"></div>
          <div className="mountain"></div>
          <div className="mountain"></div>
        </div>
        <div className="sun"></div>
        <div className="moon"></div>
  <div class="zb-outer parking">
    <div class="zb-flrs">
       <div class="f10 rel">
        <div class="zb-flr last-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          
          <i class="right-br"></i>
        </div>
      </div>
      <div class="f9 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
      <div class="f8 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          <span></span>
         
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
        <div class="f7 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
      <div class="f6 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          
         
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
      <div class="f5 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          <span class="gls-corner-left"></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
      <div class="f4 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
        <div class="f3 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          </div>
      </div>
        <div class="f2 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          
          
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
         </div>
      </div>
        <div class="f1 rel">
          <div class="house-sparkle spark-left">
            <div class="house-sparkle-dots"></div>
          </div>
        <div class="zb-flr c-gls ">
          <i class="left-br"></i>
          
          <i class="right-br"></i>
      </div>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
    </div>
  </div>
  <div class="rel full-ht">
    <div class="zb-outer">
      <div class="zb-flrs">
       <div class="f10 rel">
        <div class="zb-flr last-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-corner-left"></span>
          <i class="right-br"></i>
        </div>
      </div>
      <div class="f9 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span class="gls-balcony"></span>
          <span class="gls-balcony"></span>
          <span class="gls-balcony"></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-corner-left"></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
      <div class="f8 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-open-right"></span>
          <span></span>
          <span class="gls-corner-left"></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
        <div class="f7 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-corner-left"></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
      <div class="f6 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-corner-left"></span>
          <span class="gls-balcony"></span>
          <span class="gls-balcony"></span>
          <span class="gls-balcony"></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
      <div class="f5 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-corner-left"></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
      <div class="f4 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span></span>
          <span></span>
          <span class="gls-open-left"></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-open-right"></span>
          <span></span>
          <span></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
      </div>
        <div class="f3 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span class="gls-balcony"></span>
          <span></span>
          <span class="op5"></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-a"></span>
          <span class="gls-c"></span>
          <span class="gls-b"></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          </div>
      </div>
        <div class="f2 rel">
        <div class="zb-flr c-gls">
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
          <i class="left-br"></i>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span class="gls-balcony"></span>
          <span class="gls-balcony">
  </span>
          <span class="gls-balcony"></span>
          <i class="right-br"></i>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
         </div>
      </div>
        <div class="f1 rel">
          <div class="house-sparkle spark-left">
            <div class="house-sparkle-dots"></div>
          </div>
        <div class="zb-flr c-gls ">
          <i class="left-br"></i>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <i class="right-br"></i>
      </div>
          <div class="house-sparkle spark-right">
            <div class="house-sparkle-dots"></div>
          </div>
        </div>
    </div>

      <div class="zb-base">
        <span class="left-base"></span>
        <span></span>
        <div class="zb-entrance"></div>
        <span></span>
        <span class="right-base"></span>

      </div>
      <div class="base-grd">
      </div>
      <div class="zb-grnd"></div>
    </div>

 
  </div>
  <div id="outerCraneContainer">
  <div class="buildings">
    <div class="building-bg"></div>
    <div></div>
    <div class="1"></div>
    <div class="2"></div>
    <div class="3"></div>
    <div class="4"></div>
  </div>
  <div class="crane craneThree">
    <div class="line lineOne"></div>
    <div class="line lineTwo"></div>
    <div class="line lineThree"></div>
    <div class="stand"></div>
    <div class="weight"></div>
    <div class="cabin"></div>
    <div class="arm"></div>
  </div>
  <div class="crane craneTwo">
    <div class="line lineOne"></div>
    <div class="line lineTwo"></div>
    <div class="line lineThree"></div>
    <div class="stand"></div>
    <div class="weight"></div>
    <div class="cabin"></div>
    <div class="arm"></div>
  </div>
  <div class="crane craneOne">
    <div class="line lineOne"></div>
    <div class="line lineTwo"></div>
    <div class="line lineThree"></div>
    <div class="stand"></div>
    <div class="weight"></div>
    <div class="cabin"></div>
    <div class="arm"></div>
  </div>
</div>

  <div class="house south-plaza" id="house" data-rooms="6">
    <div class="sp-left">
      <div class="spleft-wall"></div>
    </div>
    <div class="sp-right">
      <div class="spright-wall"></div>
    </div>
  <div class="house-wings first-flr" data-flip-key="wings">
    <div class="house-left-wing">
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>
    <div class="house-right-wing">      
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>
    <div class="house-roof north-plaza">
      <div class="window-circle one"></div>
      <div class="window-circle two"></div>
      <div class="window-circle three"></div>
      <div class="window-circle four"></div>
      <div class="house-ledge"></div>
      <div class="window-circle five"></div>
      <div class="window-circle six"></div>
      <div class="window-circle seven"></div>
      <div class="window-circle eight"></div>
    </div>
  </div>
  <div class="house-wings first-flr" data-flip-key="wings">
    <div class="house-left-wing">
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>
    <div class="house-right-wing">      
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>

  </div>
    <div class="house-wings ground-flr" data-flip-key="wings">
    <div class="house-left-wing">
     
   
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>
    <div class="house-right-wing">      
  
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>

  </div>
    <div class="sp-head">
      <div class="sp-bowl"></div>
      <div class="sp-head-one"></div>
      <div class="sp-head-two"></div>
    </div>
  <div class="house-front" data-flip-key="front">
    <div class="house-facade">
      <div class="pillars"></div>
    </div>
    
    <div class="house-doorway">
      <div class="house-stairs"></div>
      <div class="house-door"></div>
    </div>
    <div class="house-gable">      
      <div class="house-roof">
        
        <div class="southplaza-ledge"></div>
      </div>
    </div>
  </div>
</div>
  
     
    <div class="house north-plaza" id="house" data-rooms="6">
  <div class="house-wings first-flr" data-flip-key="wings">
    <div class="house-left-wing">
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>
    <div class="house-right-wing">      
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>
    <div class="house-roof">
      <div class="house-ledge"></div>
    </div>
  </div>
  <div class="house-wings ground-flr" data-flip-key="wings">
    <div class="house-left-wing">
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>
    <div class="house-right-wing">      
      <div class="house-window"></div>
      <div class="house-window"></div>
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>

  </div>
  <div class="house-front" data-flip-key="front">
    <div class="house-chimney"></div>
    <div class="house-facade"></div>
    <div class="house-window">
      <div class="house-sparkle">
        <div class="house-sparkle-dots"></div>
      </div>
    </div>
    <div class="house-doorway">
      <div class="house-stairs"></div>
      <div class="house-door"></div>
    </div>
    <div class="house-gable">      
      <div class="house-roof">
        <div class="house-ledge"></div>
      </div>
    </div>
  </div>
</div>


  
  <div class="z-wall">
    <div class="container">
  
</div>
    <div class="gate">
    <div class="left">
      <div class="panel"></div>
    </div>
    <div class="right">
      <div class="panel"></div>
    </div>
  </div>

    <div class="container">
  
</div>



  </div>
  <div class="z-logo">
    <div class="z"><span>T</span></div>
    <div class="o"><span>T</span></div>
    <div class="h"><span>Y</span></div>
    <div class="oo"><span>L</span></div>
  </div>
  <div class="trees">
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
    <div class="tree-gap"></div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
      <div class="tree blown">
        <div class="branch"></div>
        <div class="branch"></div>
        <div class="trunk"></div>
        <div class="shadow"></div>
      </div>
    </div>
</div>
</div>
  );
};

export default Ttyl;