import React from 'react';
import { motion } from 'framer-motion';

const emojis = ['😢', '😭', '😿', '💔', '🌧️', '⏳', '📱', '☎️'];

const getRandomEmoji = () => emojis[Math.floor(Math.random() * emojis.length)];

const FallingEmoji = () => {
  const startX = Math.random() * 100; // Use percentage for responsiveness
  const endX = startX + (Math.random() - 0.5) * 20; // Random horizontal movement
  const duration = 5 + Math.random() * 10; // Random duration between 5 and 15 seconds
  const delay = Math.random() * 5; // Random delay up to 5 seconds
  const rotate = Math.random() * 360; // Random rotation
  const size = 24 + Math.floor(Math.random() * 24); // Random size between 24px and 48px

  return (
    <motion.div
      style={{
        position: 'absolute',
        top: -50,
        left: `${startX}%`,
        fontSize: `${size}px`,
        opacity: 0.7,
      }}
      initial={{ y: '-10vh', rotate: 0 }}
      animate={{
        y: '110vh',
        x: `${endX}%`,
        rotate: rotate,
      }}
      transition={{
        duration: duration,
        repeat: Infinity,
        delay: delay,
        ease: "linear",
      }}
    >
      {getRandomEmoji()}
    </motion.div>
  );
};

export const FallingEmojis = ({ count = 20 }) => {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none' }}>
      {[...Array(count)].map((_, i) => (
        <FallingEmoji key={i} />
      ))}
    </div>
  );
};

export default FallingEmojis;