import React from 'react';

const Hello = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Hello</h1>
        <p className="text-xl">
          This is a simple 100vh view section.
        </p>
      </div>
    </div>
  );
};

export default Hello;