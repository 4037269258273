import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import Home from './components/Home';
import About from './components/About';
import Ttyl from './components/Ttyl';
import AppSection from './components/AppSection';
import Hello from './components/Hello';
import Faq from './components/Faq';
import './App.css';

const ScrollToTopOnMount = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
}

const ScrollableSection = ({ name, component: Component }) => (
  <Element name={name} className="section">
    <Component />
  </Element>
);

const NavLink = ({ to, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(to);

    // Use react-scroll to smoothly scroll to the section
    const targetElement = to === '/' ? 'home' : to.slice(1);
    scroller.scrollTo(targetElement, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  return (
    <Link 
      to={to} 
      onClick={handleClick}
      className={`hover:text-purple-300 uppercase block ${location.pathname === to ? 'text-purple-300' : ''}`}
    >
      {children}
    </Link>
  );
};

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sections = ['home', 'about', 'ttyl', 'app', 'hello', 'faq'];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Router>
      <ScrollToTopOnMount />
      <div className="min-h-screen bg-gradient-to-b from-purple-900 to-black text-white">
        <nav className="p-4 fixed top-0 left-0 right-0 z-50 bg-purple-900 bg-opacity-80">
          <div className="container mx-auto flex justify-between items-center">
            <button
              className="lg:hidden text-white focus:outline-none"
              onClick={toggleMenu}
            >
              {isMenuOpen ? '✕' : '☰'}
            </button>
            <ul className={`${isMenuOpen ? 'flex' : 'hidden'} lg:flex flex-col lg:flex-row absolute lg:relative top-full left-0 right-0 bg-purple-900 lg:bg-transparent lg:space-x-4 p-4 lg:p-0`}>
              {sections.map((section) => (
                <li key={section} className="my-2 lg:my-0">
                  <NavLink to={`/${section === 'home' ? '' : section}`} onClick={closeMenu}>
                    {section}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </nav>

        <div className="pt-16">
          {sections.map((section) => (
            <ScrollableSection 
              key={section} 
              name={section} 
              component={
                section === 'home' ? Home :
                section === 'about' ? About :
                section === 'ttyl' ? Ttyl :
                section === 'app' ? AppSection :
                section === 'hello' ? Hello :
                Faq
              } 
            />
          ))}
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/ttyl" element={<Ttyl />} />
          <Route path="/app" element={<AppSection />} />
          <Route path="/hello" element={<Hello />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;